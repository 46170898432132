<template>
    <div
        ref="root"
        class="dropdown"
        :class="{
            'dropdown--open': isOpen,
            'dropdown--left': props.side === 'left',
            'dropdown--right': props.side === 'right',
        }"
    >
        <slot
            name="button"
            :toggle="toggle"
            :is-open="isOpen"
        >
            <button
                type="button"
                class="dropdown__button"
                :class="{ 'bg-gray-100 dark:bg-gray-800': isOpen }"
                data-toggle-dropdown
                @click.prevent.stop="toggle"
            >
                <font-awesome-icon :icon="props.icon" />
                <span v-if="props.label">{{ props.label }}</span>
            </button>
        </slot>

        <teleport
            to="body"
            :disabled="!props.fixed || !props.teleport"
        >
            <div
                v-if="isOpen"
                ref="content"
                v-click-outside="onClickOutside"
                class="dropdown__content"
                :class="[{
                    'dropdown__content--padded': props.padding,
                    'dropdown__content--fixed': props.fixed,
                    'dropdown__content--nowrap': props.nowrap,
                    'dropdown__content--modal': scroll,
                }, props.contentClass]"
                :style="props.fixed ? {
                    top: `${position.top}px`,
                    left: `${position.left}px`,
                } : {}"
            >
                <div>
                    <slot
                        :toggle="toggle"
                        :is-open="isOpen"
                    />
                </div>
            </div>
        </teleport>
    </div>
</template>

<script setup lang="ts">
    import type { Icon } from '~/types';

    export interface Props {
        icon?: Icon;
        label?: string;
        padding?: boolean;
        nowrap?: boolean;
        fixed?: boolean;
        teleport?: boolean;
        side?: 'left' | 'right';
        contentClass?: string | string[] | object;
    }

    const props = withDefaults(defineProps<Props>(), {
        icon: undefined,
        label: undefined,
        padding: true,
        nowrap: true,
        fixed: false,
        teleport: true,
        side: 'right',
        contentClass: undefined,
    });

    const emits = defineEmits<{
        onOpen: [HTMLElement];
        onClose: [void];
    }>();

    const route = useRoute();

    const root = ref<HTMLElement | null>(null);
    const content = ref<HTMLElement | null>(null);
    const scroll = ref<HTMLElement | null>(null);
    const observer = ref<ResizeObserver | null>(null);
    const isOpen = ref<boolean>(false);
    const position = ref<{ top: number; left: number }>({ top: 0, left: 0 });

    const onClickOutside = (event: TouchEvent | MouseEvent) => {
        const target = event.target as HTMLElement;
        const button = target?.closest('button[data-toggle-dropdown]');

        if (button) {
            return;
        }

        isOpen.value = false;
        emits('onClose');
    };

    const toggle = async() => {
        isOpen.value = !isOpen.value;

        if (isOpen.value) {
            await nextTick();
            await updatePosition();
            emits('onOpen', content.value!);
        } else {
            emits('onClose');
        }
    };

    const updatePosition = async() => {
        if (props.fixed) {
            if (!root.value) {
                return;
            }

            const rect = root.value.getBoundingClientRect();

            let left = props.side === 'left' ? rect.left : rect.right - rect.width;
            position.value = {
                top: rect.top + rect.height,
                left,
            };

            await nextTick();

            const contentRect = content.value?.getBoundingClientRect();
            if (contentRect) {
                const edge = window.innerWidth - contentRect.right - 10;

                if (edge < 0) {
                    left += edge;
                    position.value.left = left;
                }
            }
        } else if (props.side === 'right') {
            if (!content.value) {
                return;
            }

            const rect = content.value.getBoundingClientRect();
            const edge = window.innerWidth - rect.right - 10;

            if (edge < 0) {
                content.value.style.left = `${edge}px`;
            }
        }
    };

    watch(() => route.path, () => {
        isOpen.value = false;
    });

    if (props.fixed) {
        onMounted(() => {
            updatePosition();

            document.addEventListener('scroll', updatePosition);
            window.addEventListener('resize', updatePosition);

            scroll.value = root.value?.closest('.modal__body') ?? null;
            if (!scroll.value) {
                return;
            }

            observer.value = new ResizeObserver(() => {
                updatePosition();
            });

            observer.value.observe(scroll.value);
        });

        onUnmounted(() => {
            observer.value?.disconnect();

            document.removeEventListener('scroll', updatePosition);
            window.removeEventListener('resize', updatePosition);
        });
    }
</script>

<style lang="postcss">
    .dropdown {
        @apply relative;

        &--open {
            @apply z-[101];
        }

        &__button {
            @apply inline-block p-2 min-w-[44px] border-2 rounded border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:border-transparent;
        }

        &__content {
            @apply absolute z-[101] top-full mt-1.5 min-w-[12rem] border-2 rounded-md shadow-xl;
            @apply bg-white border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:text-white;

            &--fixed {
                @apply fixed;
            }

            &--nowrap {
                @apply whitespace-nowrap;
            }

            &--modal {
                @apply z-[999];
            }

            &--padded {
                @apply p-4;
            }
        }

        &__overlay {
            @apply block fixed inset-0 bg-black/10 pointer-events-none z-[100];
        }

        &--left &__content {
            @apply right-0;
        }

        &--right &__content {
            @apply left-0;
        }
    }
</style>
